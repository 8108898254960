import { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { useAnalytics } from "use-analytics";

import { initializeApollo } from "@/lib/apolloClient";
import { HOMEPAGE_QUERY } from "@/data";
import { ATLASGURU_DOMAIN } from "@/lib/constants";
import {
  getLocationsPhotos,
  getHeroPhotoMetadata,
  getCoverPhoto,
} from "@/lib/utils";

import Layout from "@/components/MyLayout";
import Homepage from "@/components/layouts/Homepage";

import useStyles from "../styles/homepage";

// SEO
const pageTitle = "Travel Community & Resources | Share Your Story | AtlasGuru";
const pageKeywords =
  "travel community, travel resources, adventure community, travel enthusiast, expert travel tips";
const pageDescription =
  "AtlasGuru is a fresh, new travel community where real people share their adventures and itineraries to help others plan their vacations. Find travel ideas!";

// Component
const Index = ({ homepageData }) => {
  const classes = useStyles();
  const { itinerary, customPhoto } = homepageData?.heroItinerary || {};
  const { cloudId } = getHeroPhotoMetadata(
    getLocationsPhotos(itinerary?.locationsVisited),
    itinerary?.tripPhotos || []
  );
  const heroCloudId = customPhoto?.desktop?.cloudId || cloudId;
  const coverPhoto = getCoverPhoto(heroCloudId);

  const { page } = useAnalytics();
  const { asPath } = useRouter();

  // analytics page tracking
  useEffect(() => {
    // do not track an event when a campaign modal is open
    if (asPath.includes("campaign")) return;
    page({ category: "homepage", name: "Homepage" });
  }, [page, asPath]);

  // on data fetch error
  if (!homepageData) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <Head>
        {/* SEO Tags */}
        <title key="title">{pageTitle}</title>
        {/* <meta name="keywords" key="keywords" content={pageKeywords} /> */}
        <meta name="description" key="description" content={pageDescription} />
        <meta name="image" key="image" content={coverPhoto} />

        {/* Schema.org for Google */}
        <meta itemProp="name" content={pageTitle} key="itemProp:name" />
        <meta
          itemProp="description"
          key="itemProp:description"
          content={pageDescription}
        />
        <meta itemProp="image" key="itemProp:image" content={coverPhoto} />

        {/* Open Graph general (Facebook, Pinterest & Google+) */}
        <meta property="og:title" key="og:title" content={pageTitle} />
        <meta
          property="og:article:tag"
          key="og:article:tag"
          content={pageKeywords}
        />
        <meta
          property="og:description"
          key="og:description"
          content={pageDescription}
        />
        <meta property="og:url" key="og:url" content={ATLASGURU_DOMAIN} />
        <meta property="og:image" key="og:image" content={coverPhoto} />

        {/* Twitter custom tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={coverPhoto} />
        <meta
          name="twitter:image:alt"
          content="Itineraries by travelers for  travelers"
        />
      </Head>

      <Layout classes={{ mainContainer: classes.mainContainer }}>
        <Homepage homepageData={homepageData} />
      </Layout>
    </>
  );
};

export async function getServerSideProps(context) {
  const { req, res } = context;
  const apolloClient = initializeApollo({ context });

  if (!req.url.includes("_next")) {
    // cloudfront will manage the cache invalidation for the page
    res.setHeader("Cache-Control", "max-age=31536000");
  }

  const { data } = await apolloClient.query({ query: HOMEPAGE_QUERY });
  const homepageData = data.GetHomepage ?? {};

  return {
    props: { homepageData },
  };
}

export default Index;
